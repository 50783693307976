import axios from "@/api/axios";
import store from "@/store";
import _ from "lodash";
import qs from "qs";

// 스윙영상 가지고 오기
const get_pointlist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pointlistcnt: 0,
    pointlist: [],
  };
  const token = sessionStorage.getItem("token") || null;
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/halloffame/pointlist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    headers: { Authorization: "Bearer " + token },
    params: {
      pointtype: param.pointtype,
      searchname: param.searchname,
      searchtype: param.searchtype,
      searchdate: param.searchdate,
      pageno: param.pageno,
      pagesize: param.pagesize,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_pointlist", err);
    result(false, err);
  }
};

export default {
  get_pointlist,
};
