<template>
  <div>
    <location />
  </div>
</template>

<script>
import location from "@/components/Location";

import ax from "@/api/halloffame";
import { myMixin } from "@/mixin/myMixin";
import "@/filter/common";

export default {
  name: "PointLeaderboard",
  components: {
    location,
  },
  data() {
    return {};
  },
  methods: {},
  created() {},

  mixins: [myMixin],
};
</script>
